import API_URL from './config';

function getFormDataParams(obj) {
  const data = [];
  for (const key in obj) {
    data.push(key + "=" + obj[key]);
  }
  return data.join("&");
}

async function request(config) {
  try {
    let url = config.url;
    let param = getFormDataParams(config.data);
    let fetch_parameter = {
      method: config.method,
    }
    if (config.data && config.data.real_file) {
      //if is file upload
      fetch_parameter.body = config.data.real_file
    } else {

      fetch_parameter.headers = {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      };
      if (config.method == 'GET') {
        if (param) {
          url += '?' + param;
        }
      } else if (config.method == 'POST') {
        fetch_parameter.body = param;
      }
    }
    const data = await fetch(API_URL+url, fetch_parameter);
    const data_1 = await data.text();
    const res = JSON.parse(data_1);
    return res;

  } catch (error) {
    console.log("接口错误：" + error);
  }
}

export default request;
