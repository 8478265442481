<template>
  <div id="app">
    <cx-header :locale="locale" @changeLanguage="changeLocale"/>
      <router-view />
    <cx-footer :locale="locale"/>
  </div>
</template>

<script>
import CxHeader from './components/CxHeader.vue'
import CxFooter from './components/CxFooter.vue'
export default {
  name:'App',
  components:{
    CxHeader,
    CxFooter
  },
  data(){
    return{
      message:'d',
      locale:'zh'
    }
  },
  methods: {
    changeLocale() {
      let locale = this.$i18n.locale;
      locale === "zh" ? (this.$i18n.locale = "en") : (this.$i18n.locale = "zh");
      localStorage.setItem("languageSet", this.$i18n.locale);
      this.locale = this.$i18n.locale;
      document.title=this.$t('title');
    },
  },
  mounted() {
    this.locale = this.$i18n.locale;
    document.title=this.$t('title');
    console.log(window.devicePixelRatio);
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
}

#nav {
  padding: 3rem;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
