/****   http.js   ****/
import request from "./request";

const http = {
  /**
   * methods: 请求
   * @param url 请求地址
   * @param params 请求参数
   */
  get(url, params) {
    const config = {
      method: "GET",
      url: url,
      data: params,
    };
    return request(config);
  },
  async post(url, params) {
    const config = {
      method: "POST",
      url: url,
      data: params,
    };
    return await request(config);
  },
  put(url, params) {
    const config = {
      method: "PUT",
      url: url,
      data: params,
    };
    return request(config);
  },
  delete(url, params) {
    const config = {
      method: "DELETE",
      url: url,
      data: params,
    };
    return request(config);
  },
};

export default http;
