import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'
import "./utils/px2rem"
import { fix_height, scale, is_mobile, getScrollTop } from './utils/px2rem'
import { HappyScroll } from 'vue-happy-scroll';
import 'vue-happy-scroll/docs/happy-scroll.css';

import 'swiper/dist/js/swiper';
import 'swiper/dist/css/swiper.css';
import api from './common/api'


Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: localStorage.getItem('languageSet') || 'zh', // 从localStorage里获取用户中英文选择，没有则默认中文
  messages: {
    zh: require('./components/language/zh'),
    en: require('./components/language/en')
  }
})
Vue.component('happy-scroll', HappyScroll);


Vue.config.productionTip = false; //生产环境console
Vue.prototype.is_mobile = is_mobile;//全局变量
Vue.prototype.scale = scale;//全局变量
Vue.prototype.rate = scale;//全局变量
Vue.prototype.fix_height = fix_height;//全局变量
Vue.prototype.getScrollTop = getScrollTop;//全局变量
Vue.prototype.api = api;//全局变量


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
