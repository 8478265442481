<template>
  <div class="cx-header" :class="[
    is_mobile ? 'm-cx-header' : 'pc-cx-header',
    is_rel ? 'cx-header-top' : '',
    router_path == '/' && is_no_bgcolor ? 'cx-header-op' : '',
  ]">
    <img class="cx-logo" :src="logo[locale]" />
    <div class="cx-nav" v-if="!is_mobile || (is_mobile && m_nav_show)" :class="is_mobile ? 'm-cx-nav' : ''">
      <router-link to="/">
        <div @click="toggleNav" class="cx-top-btn m-cx-top-btn"
          :class="{ 'cx-top-btn-act': !is_mobile && nav_act_index == 0 }">
          {{ $t("topbar.home") }}
          <div class="cx-b-bo"></div>
        </div>
      </router-link>
      <div class="cx-top-btn cx-pro-top-btn" :class="[
        {
          'm-cx-top-sp': production_nav_spread,
          'cx-top-btn-act': !is_mobile && nav_act_index == 1,
        },
      ]" @click="toggleSub()">
        {{ $t("topbar.productions") }}
        <div class="cx-b-bo"></div>
        <div class="cx-b-sub" v-if="prepare_for_show">
          <div class="cx-sub-btn">
            <router-link to="/pro/razorDCX">{{ $t("topbar.productions_razor_dcx") }}
              <span v-if="is_mobile">></span>
            </router-link>
            <div class="cx-sub-pro-box">
              <!-- 张伟20240516 -->
              <router-link to="/pro/razorDCX-tongass">
                <div class="cx-sub-pro-btn">
                  RazorDCX Tongass <span v-if="is_mobile">></span>
                </div>
              </router-link>
              <router-link to="/pro/razorDCX-congo">
                <div class="cx-sub-pro-btn">
                  RazorDCX Congo <span v-if="is_mobile">></span>
                </div>
              </router-link>
              <router-link to="/pro/razorDCX-takla">
                <div class="cx-sub-pro-btn">
                  RazorDCX Takla <span v-if="is_mobile">></span>
                </div>
              </router-link>
              <router-link to="/pro/razorDCX-pantanal">
                <div class="cx-sub-pro-btn">
                  RazorDCX Pantanal <span v-if="is_mobile">></span>
                </div>
              </router-link>
              <router-link to="/pro/razorDCX-tarkine">
                <div class="cx-sub-pro-btn">
                  RazorDCX Tarkine <span v-if="is_mobile">></span>
                </div>
              </router-link>
            </div>
          </div>
          <div class="cx-sub-btn">
            <router-link to="/pro/razorWareX">{{ $t("topbar.productions_razor_ware_x") }}
              <span v-if="is_mobile">></span></router-link>
            <div class="cx-sub-pro-box">
              <router-link to="/pro/razorWareX-version1">
                <div class="cx-sub-pro-btn">
                  RazorWareX 1.0 <span v-if="is_mobile">></span>
                </div>
              </router-link>
            </div>
          </div>
          <div class="cx-sub-btn">
            {{ $t("topbar.productions_razor_aikit") }}
            <div class="cx-sub-pro-box">
              <router-link to="/pro/razoraikit">
                <div class="cx-sub-pro-btn">
                  RazorAIKit <span v-if="is_mobile">></span>
                </div>
              </router-link>
            </div>
          </div>
        </div>

        <img v-if="is_mobile" class="m-nav-narrow" :src="nav_up_icon" />
      </div>

      <router-link to="/news" :class="{ 'cx-top-btn-act': !is_mobile && nav_act_index == 2 }">
        <div @click="toggleNav" class="cx-top-btn">
          {{ $t("topbar.news") }}
          <div class="cx-b-bo"></div>
        </div>
      </router-link>
      <router-link to="/about" :class="{ 'cx-top-btn-act': !is_mobile && nav_act_index == 3 }">
        <div @click="toggleNav" class="cx-top-btn">
          {{ $t("topbar.about_us") }}
          <div class="cx-b-bo"></div>
        </div>
      </router-link>
      <router-link to="/join" :class="{ 'cx-top-btn-act': !is_mobile && nav_act_index == 4 }">
        <div @click="toggleNav" class="cx-top-btn">
          {{ $t("topbar.join_us") }}
          <div class="cx-b-bo"></div>
        </div>
      </router-link>

      <cx-search :show_search="show_search" @toggleSearch="toggleSearch"> </cx-search>
    </div>
    <img class="cx-search-btn" @click="toggleSearch" v-if="!is_mobile || (is_mobile && m_nav_show)" :src="seach_icon" />
    <div class="cx-language cx-language-zh" :class="locale == 'zh' ? 'cx-language-curr' : ''"
      @click="$emit('changeLanguage')">
      中
    </div>
    <div class="cx-language cx-language-en" :class="locale == 'en' ? 'cx-language-curr' : ''"
      @click="$emit('changeLanguage')">
      EN
    </div>

    <div v-if="is_mobile" class="m-btn-t" :class="[{ 'm-btn-tact': m_btn_act }]" @click="toggleNav()">
      <div class="m-btn-line m-btn-line-a"></div>
      <div class="m-btn-line m-btn-line-b"></div>
      <div class="m-btn-line m-btn-line-c"></div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import Search from "../components/Search.vue";
export default {
  name: "Header",
  components: {
    "cx-search": Search,
  },
  props: {
    locale: {
      type: String,
    },
  },
  data() {
    return {
      is_rel: false,
      show_search: false,
      logo: {
        zh: require("@/assets/logo-zh.png"),
        en: require("@/assets/logo-en.png"),
      },
      nav_up_icon: require("@/assets/nav-up-icon.png"),
      seach_icon: require("@/assets/seach-icon.png"),
      production_nav_spread: false,
      router_path: "/",
      m_nav_show: true,
      m_btn_act: false,

      nav_act_index: 0,
      is_no_bgcolor: true,

      prepare_for_show: true,
    };
  },
  watch: {},
  methods: {
    toggleSearch() {
      this.show_search = !this.show_search;
    },
    toggleSub() {
      if (this.is_mobile) {
        this.production_nav_spread = !this.production_nav_spread;
      }
    },
    toggleNav() {
      let that = this;
      if (that.m_nav_show) {
        //隐藏
        that.m_btn_act = false;
        if (this.is_mobile) {
          gsap.to(".cx-nav", {
            opacity: 0,
            y: 0,
            onComplete: function () {
              that.m_nav_show = false;
            },
          });
        }
      } else {
        //显示
        that.m_btn_act = true;
        that.m_nav_show = true;
        gsap.to(".cx-nav", { opacity: 1, y: 0, delay: 50, onComplete: function () { } });
      }
    },

    checkNav(router_path) {
      let that = this;
      function setIndexTopBarBg() {
        const scroll_top = that.getScrollTop();
        if (scroll_top > 10) {
          that.is_no_bgcolor = false;
        } else {
          that.is_no_bgcolor = true;
        }
      }
      this.is_no_bgcolor = false;
      this.is_rel = false;
      if (router_path == "/") {
        this.nav_act_index = 0;
        this.is_no_bgcolor = true;
        window.addEventListener("scroll", setIndexTopBarBg);
      } else if (router_path.indexOf("/pro/") >= 0) {
        this.nav_act_index = 1;
        if (
          // 张伟20240516
          router_path == "/pro/razorDCX-tongass" ||
          router_path == "/pro/razorDCX-congo" ||
          router_path == "/pro/razorDCX-takla" ||
          router_path == "/pro/razorDCX-tarkine" ||
          router_path == "/pro/razorDCX-pantanal" ||
          router_path == "/pro/razorWareX-version1" ||
          router_path == "/pro/razoraikit"
        ) {
          this.is_rel = true;
        }
      } else if (router_path == "/news") {
        this.nav_act_index = 2;
      } else if (router_path == "/about") {
        this.nav_act_index = 3;
        this.is_rel = true;
      } else if (
        router_path.indexOf("/join") >= 0 ||
        router_path.indexOf("/recruitment") >= 0
      ) {
        this.nav_act_index = 4;
      } else if (router_path.indexOf("/search") >= 0) {
        this.nav_act_index = -1;
        this.is_rel = true;
      }
    },
  },
  mounted() {
    if (this.is_mobile) {
      this.m_nav_show = false;
    }

    let that = this;
    setTimeout(() => {
      that.checkNav(this.$route.path);
    }, 500);

    this.$router.beforeEach((to, from, next) => {
      if (window._hmt) {
        if (to.path) {
          window._hmt.push(["_trackPageview", "/#" + to.fullPath]);
        }
      }
      this.prepare_for_show = false;
      next();
    });
    this.$router.afterEach((to) => {
      if (this.is_mobile) {
        this.m_nav_show = false;
        this.m_btn_act = false;
      }
      this.router_path = to.path;
      this.checkNav(this.router_path);
      window.document.documentElement.scrollTo({ top: 0 });
      let that = this;
      setTimeout(function () {
        that.prepare_for_show = true;
      }, 100);
    });
  },
};
</script>

<style lang="less" scoped>
.cx-header {
  position: fixed;
  width: 100%;
  z-index: 11111;
  background-color: rgba(0, 0, 0, 1);
  transition: 300ms all linear;

  .cx-logo {
    position: absolute;
  }

  .cx-nav {
    padding-top: 31.5px;

    .cx-top-btn {
      position: relative;

      .cx-b-bo {
        position: absolute;
        background-color: rgba(0, 183, 255, 1);
        box-shadow: 0px 2px 4px 0px rgba(0, 183, 255, 1);
        height: 2px;
        bottom: 0px;
        width: 0;
        opacity: 0;
        transition: all 200ms linear;
      }
    }
  }

  .cx-top {
    position: relative !important;
  }

  .cx-search-btn {
    position: absolute;
  }

  .cx-language {
    position: absolute;
    border: #fff 2px solid;
    text-align: center;
    color: #fff;
  }

  .cx-language-curr {
    background-color: #fff;
    color: #000;
  }
}

.cx-header-top {
  position: relative;
}

/**pc */
.pc-cx-header {
  height: 100px;

  .cx-logo {
    width: 189px;
    top: 19px;
    left: 83px;
  }

  .cx-nav {
    padding-top: 31.5px;

    .cx-top-btn {
      display: inline-block;
      font-size: 16px;
      padding: 10px 0px;
      margin: 0px 60px;
      font-weight: 400;
      color: #fff;
      cursor: pointer;
    }

    .cx-top-btn:hover .cx-b-bo {
      width: 100%;
      opacity: 1;
    }

    .cx-top-btn-act .cx-b-bo {
      width: 100%;
      opacity: 1;
    }

    .cx-pro-top-btn {
      position: relative;

      .cx-b-sub {
        position: absolute;
        display: block;
        z-index: 1111;
        width: 190px;
        left: 50%;
        margin-left: -95px;
        top: 35px;
        display: none;
      }

      .cx-sub-btn {
        position: relative;
        width: 190px;
        height: 48px;
        line-height: 48px;
        background-color: rgba(83, 84, 85, 1);
        transition: 300ms all linear;

        a {
          color: #fff;
        }

        .cx-sub-pro-box {
          position: absolute;
          width: 190px;
          left: 100%;
          top: 0px;
          display: none;

          .cx-sub-pro-btn {
            width: 190px;
            height: 48px;
            line-height: 48px;
            background-color: rgba(83, 84, 85, 1);
            transition: 300ms all linear;

            a {
              color: #fff;
            }
          }

          .cx-sub-pro-btn:hover {
            background-color: rgba(105, 105, 105, 1);
          }
        }
      }

      .cx-sub-btn:hover {
        background-color: rgba(105, 105, 105, 1);

        .cx-sub-pro-box {
          display: block;
        }
      }
    }

    .cx-pro-top-btn:hover {
      .cx-b-sub {
        display: block;
      }
    }
  }

  .cx-search-btn {
    top: 34px;
    right: 199px;
    width: 36px;
    cursor: pointer;
  }

  .cx-language {
    width: 43px;
    height: 43px;
    border-radius: 43px;
    line-height: 44px;
    font-size: 16px;
    top: 30.4px;
    cursor: pointer;
  }

  .cx-language-zh {
    right: 105.8px;
  }

  .cx-language-en {
    right: 70.7px;
  }
}

.cx-header-op {
  background-color: rgba(0, 0, 0, 0);
}

/**mobile */
.m-cx-header {
  height: 90px;

  .cx-logo {
    width: 154px;
    top: 12px;
    left: 26px;
    z-index: 11111;
  }

  .cx-nav {
    margin: 0px;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #000;

    .cx-top-btn {
      font-size: 26px;
      overflow: hidden;
      width: 567px;
      font-weight: 400;
      display: block;
      height: 80px;
      margin: 0 auto;
      text-align: center;
      line-height: 80px;
      color: #fff;
      border-bottom: rgba(61, 61, 61, 1) solid 1px;
      text-align: left;

      .m-nav-narrow {
        position: absolute;
        right: 0px;
        top: 30px;
        width: 27.5px;
        transform: rotate(0deg);
        transition: all 300ms linear;
      }

      .cx-b-bo {
        position: absolute;
        background-color: rgba(0, 183, 255, 1);
        height: 2px;
        bottom: 0px;
        width: 0;
        opacity: 0;
        transition: all 200ms linear;
      }

      .cx-b-sub {
        position: relative;

        .cx-sub-btn {
          position: relative;
          padding-left: 25px;

          a {
            color: #fff;
          }

          .cx-sub-pro-box {
            .cx-sub-pro-btn {
              a {
                color: #fff;
              }
            }
          }
        }

        .cx-sub-btn::before {
          position: absolute;
          display: block;
          content: "";
          border-radius: 5px;
          width: 5px;
          height: 5px;
          top: 37px;
          left: 10px;
          background-color: #fff;
        }
      }
    }

    .m-cx-top-btn {
      margin-top: 158px;
    }

    .m-cx-top-sp {
      height: auto;

      .m-nav-narrow {
        top: 20px;
        transform: rotate(-180deg);
      }
    }
  }

  .m-cx-nav {
    opacity: 1;
  }

  .cx-search-btn {
    top: 29px;
    right: 224px;
    width: 32px;
  }

  .cx-language {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    line-height: 51px;
    font-size: 19px;
    top: 19px;
    cursor: pointer;
  }

  .cx-language-zh {
    right: 130px;
  }

  .cx-language-en {
    right: 89.5px;
  }

  .m-btn-t {
    position: absolute;
    top: 31px;
    right: 23px;

    .m-btn-line {
      height: 2px;
      width: 24px;
      margin-bottom: 8px;
      background-color: #fff;
      border-radius: 2px;
      transition: all 300ms linear;
    }
  }

  .m-btn-tact {
    .m-btn-line-b {
      opacity: 0;
    }

    .m-btn-line-a {
      transform: rotate(45deg) translateY(4px) translateX(10px);
      width: 30px;
    }

    .m-btn-line-c {
      transform: rotate(-45deg) translateY(-4px) translateX(10px);
      width: 30px;
    }
  }
}
</style>
