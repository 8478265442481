<template>
  <div class="cover-layout">
    <div class="cover-center">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default{
  name: "About",
  props: {
  },
  data() {
    return {
    };
  },
  methods:{
  },
  components: {},
  mounted() {
    
  }
}
</script>
<style lang="less" scoped>
.cover-layout{
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  top: 0px;
  z-index: 99999;
  left: 0px;
}
.cover-center{
  position: absolute;
  left:50%;
  top:50%;
}
</style>