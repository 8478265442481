<template>
  <div class="comm-index" :class="is_mobile ? 'm-index' : 'pc-index'">
    <div class="swiper cx-in-screen" :style="'height:' + window_height + 'px'">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, i) in banner" :key="i">
          <div v-if="!item.link">
          <img
              v-if="is_mobile && !item['mobile_' + $t('language.shot_name') + '_video']"
              class="cx-sl-size"
              :src="item['mobile_' + $t('language.shot_name') + '_thumb']"
            />
          </div>
          <div v-if="!item.link">
          <img
            v-if="!is_mobile && !item['pc_' + $t('language.shot_name') + '_video']"
            class="cx-sl-size"
            :src="item['pc_' + $t('language.shot_name') + '_thumb']"
          />
          </div>
          <a v-if="item.link" :href="item.link" target="_blank">
            <img
              v-if="is_mobile && !item['mobile_' + $t('language.shot_name') + '_video']"
              class="cx-sl-size"
              :src="item['mobile_' + $t('language.shot_name') + '_thumb']"
            />
          </a>
          <a v-if="item.link" :href="item.link" target="_blank">
          <img
            v-if="!is_mobile && !item['pc_' + $t('language.shot_name') + '_video']"
            class="cx-sl-size"
            :src="item['pc_' + $t('language.shot_name') + '_thumb']"
          />
          </a>
          <video
            autoplay="autoplay"
            loop="loop"
            x5-playsinline=""
            playsinline="true"
            x-webkit-arplay="true"
            x5-video-player-type="h5"
            webkit-playsinline="true"
            v-if="is_mobile && item['mobile_' + $t('language.shot_name') + '_video']"
            class="cx-sl-size"
            :src="item['mobile_' + $t('language.shot_name') + '_thumb']"
          ></video>
          <video
            autoplay="autoplay"
            loop="loop"
            x5-playsinline=""
            playsinline="true"
            x-webkit-arplay="true"
            x5-video-player-type="h5"
            webkit-playsinline="true"
            v-if="!is_mobile && item['pc_' + $t('language.shot_name') + '_video']"
            class="cx-sl-size"
            :src="item['pc_' + $t('language.shot_name') + '_thumb']"
          ></video>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
    <div class="cx-gsap-box">
      <div class="cx-advantage">
        <img class="cx-ad-bg" :src="i_bg_a" />
        <div class="cx-ad-box">
          <div class="cx-ad-title">
            {{ $t("home.advantage_title") }}
          </div>
          <div class="cx-ad-txt">
            {{ $t("home.advantage_txt") }}
          </div>
          <div class="cx-it-box">
            <div class="swiper-wrapper">
              <div class="swiper-slide cx-ad-item cx-ad-item-a">
                <img :src="i_science_techology" />
                <div class="cx-ad-label">{{ $t("home.adv_science_tech") }}</div>
              </div>
              <div class="swiper-slide cx-ad-item cx-ad-item-b">
                <img :src="i_intellgent" />
                <div class="cx-ad-label">{{ $t("home.adv_smart") }}</div>
              </div>
              <div class="swiper-slide cx-ad-item cx-ad-item-c">
                <img :src="i_opennes" />
                <div class="cx-ad-label">{{ $t("home.adv_open") }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cx-ta-container">
        <img class="cx-tad-bg" :src="i_adv_bg" />
        <div class="cx-tech-adv">
          <div class="cx-ta-item">
            <!-- 生态-->
            <div class="cx-ta-desc-box cx-td-bimg an-m-2">
              <div class="cx-ta-tit">
                <img class="cx-ad-nu" :src="icon_01" />
                {{ $t("home.ecology_ablity") }}
              </div>
              <div class="cx-ta-desc an-cx-des">
                {{ $t("home.ecology_ablity_desc") }}
              </div>
            </div>
            <img class="cx-ta-img cx-td-aimg an-cx-a an-m-1" :src="i_ecology" />
          </div>
          <div class="cx-ta-item an-cx-b">
            <!-- 精准-->
            <div class="cx-ta-desc-box cx-td-aimg an-m-1">
              <div class="cx-ta-tit">
                <img class="cx-ad-nu" :src="icon_02" />
                {{ $t("home.precise_local") }}
              </div>
              <div class="cx-ta-desc an-cx-des">
                {{ $t("home.precise_local_desc") }}
              </div>
            </div>
            <img class="cx-ta-img cx-td-bimg an-cx-b an-m-2" :src="i_precise_local" />
          </div>
          <div class="cx-ta-item">
            <!-- 技术-->
            <div class="cx-ta-desc-box cx-td-bimg an-m-4">
              <div class="cx-ta-tit">
                <img class="cx-ad-nu" :src="icon_03" />
                {{ $t("home.precede_tech") }}
              </div>
              <div class="cx-ta-desc an-cx-des">
                {{ $t("home.precede_tech_desc") }}
              </div>
            </div>
            <img class="cx-ta-img cx-td-aimg an-cx-a an-m-3" :src="i_precede_techology" />
          </div>
          <div class="cx-ta-item">
            <!-- 商业 -->
            <div class="cx-ta-desc-box cx-td-aimg an-m-3">
              <div class="cx-ta-tit">
                <img class="cx-ad-nu" :src="icon_04" />
                {{ $t("home.bussiness_model") }}
              </div>
              <div class="cx-ta-desc an-cx-des">
                {{ $t("home.bussiness_model_desc") }}
              </div>
            </div>
            <img class="cx-ta-img cx-td-bimg an-cx-b an-m-4" :src="i_business_model" />
          </div>
        </div>
      </div>
      <div class="cx-pro-box" :style="'height:' + window_height + 'px'">
        <div class="cx-pro-name cx-pn-1">{{ $t("home.pro_razor_dcx_tit") }}</div>
        <div class="cx-pro-name cx-pn-2">{{ $t("home.pro_razor_warex_tit") }}</div>
        <div class="cx-center">
          <img
            class="cx-pro-bg"
            :src="is_mobile ? car_prespect_img_m : car_prespect_img"
          />
          <div class="cx-pro-ani-1">
            <img class="cx-pro-raz cx-pro-raz-1" :src="i_pro_razord" />
            <div class="cx-pro-desc-msg cx-pro-desc-msg-1">
              <div class="cx-decorator cx-decorator-an1 cx-decorator-1"></div>
              <div class="cx-decorator cx-decorator-an1 cx-decorator-2"></div>
              <div class="cx-decorator cx-decorator-an1 cx-decorator-3"></div>
              <div class="cx-decorator cx-decorator-an1 cx-decorator-4"></div>
              <div class="cx-pro-ra">
                <div class="cx-pro-dmsg cx-pro-dmsg-1">
                  {{ $t("home.pro_razor_dcx_desc") }}
                </div>
                  <router-link to="/pro/razorDCX">
                <div class="cx-pro-dbtn">
                    {{ $t("home.pro_desc_btn") }}
                </div>
                  </router-link>
              </div>
            </div>
            <div class="narraw narraw-1">
              <div class="line-a">
                <div class="line-b"></div>
              </div>
            </div>
          </div>
          <div class="cx-pro-ani-2">
            <img class="cx-pro-razw cx-pro-raz-2" :src="i_pro_razorw" />
            <div class="cx-pro-desc-msg cx-pro-desc-msg-2">
              <div class="cx-decorator cx-decorator-an2 cx-decorator-1"></div>
              <div class="cx-decorator cx-decorator-an2 cx-decorator-2"></div>
              <div class="cx-decorator cx-decorator-an2 cx-decorator-3"></div>
              <div class="cx-decorator cx-decorator-an2 cx-decorator-4"></div>
              <div class="cx-pro-ra">
                <div class="cx-pro-dmsg cx-pro-dmsg-2">
                  {{ $t("home.pro_razor_warex_desc") }}
                </div>
                    <router-link to="/pro/razorWareX">
                      <div class="cx-pro-dbtn">
                          {{ $t("home.pro_desc_btn") }}
                      </div>
                    </router-link>
              </div>
            </div>
            <div class="narraw narraw-2">
              <div class="line-a">
                <div class="line-b"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cx-join-box">
      <img class="cx-jo-img" :src="is_mobile ? join_bn_img_m : join_bn_img" />
      <div class="cx-jo-msg">
        <div class="cx-jo-ti">{{ $t("home.join_us") }}</div>
        <div class="cx-jo-vi">{{ $t("home.vendor") }}</div>
        <router-link to="/join"
          ><div class="cx-jo-btn">{{ $t("home.join_us") }}</div></router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "Home",
  data() {
    return {
      banner: [],

      i_bg_a: require("@/assets/i-bg-a.png"),
      i_science_techology: require("@/assets/i-science-techology.png"),
      i_intellgent: require("@/assets/i-intellgent.png"),
      i_opennes: require("@/assets/i-openness.png"),
      i_adv_bg: require("@/assets/i-adv-bg.png"),
      icon_01: require("@/assets/01@2x.png"),
      icon_02: require("@/assets/02@2x.png"),
      icon_03: require("@/assets/03@2x.png"),
      icon_04: require("@/assets/04@2x.png"),
      i_ecology: require("@/assets/i-ecology.png"),
      i_precise_local: require("@/assets/i-precise-local.png"),
      i_precede_techology: require("@/assets/i-precede-techology.png"),
      i_business_model: require("@/assets/i-business-model.png"),
      i_pro_razord: require("@/assets/i-pro-razord.png"),
      i_pro_razorw: require("@/assets/i-pro-razorw.png"),

      car_prespect_img: require("@/assets/i-pro-bg.png"),
      car_prespect_img_m: require("@/assets/i-pro-bg-m.png"),
      join_bn_img: require("@/assets/i-join-us.png"),
      join_bn_img_m: require("@/assets/i-join-us-m.png"),

      window_height: document.documentElement.clientHeight,
      window_width: document.documentElement.clientWidth,

      tl:null,
      tl2:null,
      tl3:null,

    };
  },
  watch: {
    window_width: () => {
    },
    scale: () => {
    },
    window_height: () => {
    },
  },
  components: {
    
  },
  destroyed(){
    if(this.tl){
      this.tl.kill();
      this.tl=null;
    }
    this.tl2.kill();
    this.tl2=null;
    this.tl3.kill();
    this.tl3=null;
  },
  mounted() {
    this.api.banner().then((res) => {
      if (res.code == 200) {
        this.banner = res.data;
        this.banner.forEach((element) => {
          if (element.mobile_cn_thumb.indexOf(".mp4") > 0) {
            element["mobile_cn_video"] = true;
          } else {
            element["mobile_cn_video"] = false;
          }
          if (element.mobile_en_thumb.indexOf(".mp4") > 0) {
            element["mobile_en_video"] = true;
          } else {
            element["mobile_en_video"] = false;
          }
          if (element.pc_cn_thumb.indexOf(".mp4") > 0) {
            element["pc_cn_video"] = true;
          } else {
            element["pc_cn_video"] = false;
          }
          if (element.pc_en_thumb.indexOf(".mp4") > 0) {
            element["pc_en_video"] = true;
          } else {
            element["pc_en_video"] = false;
          }
        });
        this.$nextTick(() => {
          if(this.banner.length>1){
            new Swiper(".swiper", {
              speed: 2000,
              loop: true,
              autoplay: {
                delay: 5000,
                disableOnInteraction: false,
              },
              pagination: {
                el: ".swiper-pagination",
                clickable: true,
              },
            });
          }else{
            new Swiper(".swiper", {
              pagination: {
                el: ".swiper-pagination",
                clickable: true,
              },
            });
          }
        });
      }
    });

    let slidesPerView=3;
    if(this.is_mobile){
      slidesPerView=1
    }
    new Swiper(".cx-it-box", {
      slidesPerView: slidesPerView,
      spaceBetween: 30,
      initialSlide: 1,
      loop: false,
    });
    if (!this.is_mobile) {
      this.tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".cx-advantage",
          // pin: true, // pin the trigger element while active
          start: "-600 top", // when the top of the trigger hits the top of the viewport
          end: "+=500", // end after scrolling 500px beyond the start
          scrub: 0, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
          // snap: {
          //   snapTo: "labels", // snap to the closest label in the timeline
          //   duration: { min: 0.2, max: 3 }, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
          //   delay: 0, // wait 0.2 seconds from the last scroll event before doing the snapping
          //   ease: "power1.inOut", // the ease of the snap animation ("power3" by default)
          // },
        },
      });
      // add animations and labels to the timeline
      this.tl.addLabel("title")
        .from(".cx-ad-title", { opacity: 0, y: 30 })
        .from(".cx-ad-txt", { opacity: 0, y: 30 })
        .from(".cx-ad-item-a", { opacity: 0, y: 30 })
        .from(".cx-ad-item-b", { opacity: 0, y: 30 },'<')
        .from(".cx-ad-item-c", { opacity: 0, y: 30 },'<');
    }
    this.tl2 = gsap.timeline({
      // yes, we can add it to an entire timeline!
      scrollTrigger: {
        trigger: ".cx-ta-container",
        pin: true, // pin the trigger element while active
        start: "-50 top", // when the top of the trigger hits the top of the viewport
        end: "+=1000", // end after scrolling 500px beyond the start
        scrub: 0, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
      },
    });

    let scale = this.scale;

    let trans_s = 320 * scale;
    if (this.is_mobile) {
      trans_s = 0;
    }

    if (!this.is_mobile) {
      this.tl2
        .addLabel("begin")
        .from(".an-m-1", { x: trans_s, y: -370 * scale }) //生态能力
        .from(".an-m-2", { x: -trans_s, y: -80 * scale }, "<") //精准定位
        .from(".an-m-3", { x: trans_s, y: -950 * scale }, "<") //商业模式
        .from(".an-m-4", { x: -trans_s, y: -660 * scale }, "<") //技术领先
        .from(".an-cx-des", { opacity: 0, y: 20 }, "-=0.2")
        .from(".cx-ta-img", { opacity: 0 }, "-=0.2")
    } else {
      this.tl2
        .addLabel("begin")
        .from(".an-m-1", { y: -580 * scale }) //生态能力
        .from(".an-m-2", { y: 0 * scale }, "<") //精准定位
        .from(".an-m-3", { y: -1730 * scale }, "<") //商业模式
        .from(".an-m-4", { y: -1150 * scale }, "<") //技术领先
        .from(".an-cx-des", { opacity: 0, y: 20 }, "-=0.2")
        .from(".cx-ta-img", { opacity: 0 }, "-=0.2")
    }

    this.tl3 = gsap.timeline({
      scrollTrigger: {
        trigger: ".cx-pro-box",
        pin: true,
        start: "top top",
        end: "+=1000",
        scrub: 0
      },
    });

    this.tl3
      .from(".cx-pro-raz-1", { opacity: 0 })
      .from(".cx-pn-1", { opacity: 0, y: -50 }, "<")
      .from(".narraw-1", { opacity: 0 })
      .from(".cx-pro-desc-msg-1", { opacity: 0 })
      .from(".cx-pro-dmsg-1", { scale: 0 })
      .from(".cx-pro-dbtn-1", { opacity: 0 })
      .from(".cx-decorator-an1", { opacity: 0 }, "-=0.2")

      .addLabel("step1")
      .from(".cx-pro-raz-1", { opacity: 1 }, "+=2.5")
      .from(".narraw-1", { opacity: 1 }, "<")
      .from(".cx-pro-desc-msg-1", { opacity: 1 }, "<")
      .from(".cx-pro-dmsg-1", { scale: 1 }, "<")
      .from(".cx-pro-dbtn-1", { opacity: 1 }, "<")
      .from(".cx-decorator-an1", { opacity: 1 }, "<")
      .from(".cx-pn-1", { opacity: 1 }, "<")

      .from(".cx-pro-raz-2", { opacity: 0 })
      .from(".cx-pn-2", { opacity: 0, y: -50 }, "<")
      .from(".narraw-2", { opacity: 0 })
      .from(".cx-pro-desc-msg-2", { opacity: 0,scale:0 })
      .from(".cx-pro-dmsg-2", { scale: 0 })
      .from(".cx-pro-dbtn-2", { opacity: 0 })
      .from(".cx-decorator-an2", { opacity: 0 }, "-=0.2");

  },
};
</script>

<style lang="less">
.comm-index {
  .container {
    position: relative;
    width: 100%;
    height: 10000px;
  }
  .box {
    width: 100px;
    height: 100px;
    top: 40px;
  }
  .swiper-slide {
    position: relative !important;
    padding: 0px;
    overflow: hidden;
  }
  .cx-sl-size {
    position: absolute;
    display: block;
    width: 100%;
    height: auto;
  }

  .cx-sl-size-h {
    position: absolute;
    display: block;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
  }
  .swiper-pagination {
    bottom: 37px !important;
  }
  .swiper-pagination-bullet {
    position: relative !important;
    width: 8px !important;
    height: 8px !important;
    margin: 0 32px !important;
    background-color: #fff !important;
    opacity: 1 !important;
  }
  .swiper-pagination-bullet::after {
    content: "";
    display: block;
    width: 0px;
    height: 0px;
    border-radius: 32px;
    top: 50%;
    left: 50%;
    margin-left: 0px;
    margin-top: 0px;
    border: #fff solid 2px;
    opacity: 0;
    transition: all linear 100ms;
  }
  .swiper-pagination-bullet-active::after {
    width: 32px;
    height: 32px;
    margin-left: -14px;
    margin-top: -14px;
    opacity: 1;
  }
  .cx-advantage {
    position: relative;
    width: 1920px;
    height: 709px;
    color: #fff;
  }
  .cx-ad-bg {
    position: absolute;
    width: 1920px;
    height: 709px;
    left: 0px;
    top: 0px;
    z-index: 1;
  }

  .cx-ad-box {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .cx-ad-title {
    font-size: 44px;
    margin-top: 83px;
    margin-bottom: 24px;
  }

  .cx-ad-txt {
    font-size: 16px;
    line-height: 34px;
    white-space: pre-wrap;
  }

  .cx-ad-item {
    position: relative;
    width: 460px;
    height: 317px;
    display: inline-block;
    // margin: 0 20px;
  }

  .cx-ad-item img {
    position: absolute;
    display: block;
    width: 100%;
    z-index: 1;
  }

  .cx-it-box {
    width: 580px;
    margin: 40px auto;
  }
  .cx-ad-label {
    position: absolute;
    z-index: 22;
    bottom: 17px;
    font-size: 26px;
    width: 100%;
    text-align: center;
  }
  .cx-join-box {
    position: relative;
    width: 1920px;
    height: 397px;
    text-align: center;
    overflow: hidden;
  }
  .cx-jo-img {
    position: absolute;
    width: 1920px;
    height: 397px;
    left: 0px;
    top: 0px;
  }
  .cx-jo-msg {
    position: absolute;
    width: 400px;
    z-index: 10;
    top: 95px;
    right: 270px;
  }
  .cx-jo-ti {
    font-size: 44px;
    font-weight: 500;
    color: #fff;
  }

  .cx-jo-vi {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    margin: 18px auto 41px;
  }

  .cx-jo-btn {
    position: relative;
    width: 170px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border-radius: 5px;
    font-size: 14px;
    background-color: rgba(144, 218, 252, 1);
    margin: 0 auto;
    color: #000;
  }
  .cx-pro-box {
    position: relative;
    width: 1920px;
    height: 1080px;
    overflow: hidden;
  }
  .cx-pro-bg {
    position: absolute;
    width: 1920px;
    height: 1080px;
    left: -960px;
    top: -540px;
    z-index: 11;
  }
  .cx-center {
    position: absolute;
    left: 50%;
    top: 50%;
  }
  .cx-tech-adv {
    position: relative;
    width: 1216px;
    margin: 0 auto;
    overflow: hidden;
    padding-top: 88px;
    height: 1614px;
  }
  .cx-ta-item {
    position: relative;
    width: 1200px;
    height: 384px;
    left: 50%;
    margin-left: -600px;
  }
  .cx-ta-img {
    position: relative;
    width: 555px;
    height: 384px;
  }
  .cx-ta-desc-box {
    width: 555px;
    float: left;
  }
  .cx-ta-tit {
    width: 555px;
    font-size: 40px;
    line-height: 45px;
    font-weight: 700;
    color: #fff;
    z-index: 22;
    margin-top: 115px;
    margin-bottom: 26px;
    text-align: center;
    color: transparent;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(158, 234, 255, 1) 100%
    );
    -webkit-background-clip: text;
  }
  .cx-ta-desc {
    width: 555px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    line-height: 34px;
    white-space: pre-wrap;
    margin-top: 10px;
    overflow: hidden;
  }

  .cx-td-aimg {
    float: left;
  }
  .cx-td-bimg {
    float: right;
  }

  .cx-tad-bg {
    position: absolute;
    width: 1920px;
    bottom: 0px;
    left: 0px;
  }
  .cx-ta-container {
    position: relative;
    width: 100%;
  }

  .cx-ad-nu {
    margin-top: -10px;
    width: 84px;
  }
  .cx-pro-raz {
    position: absolute;
    width: 567px;
    height: 364px;
    z-index: 22;
    left: -340px;
    top: -120px;
  }
  .cx-pro-razw {
    position: absolute;
    width: 1355px;
    height: 763px;
    z-index: 22;
    left: -710px;
    top: -350px;
  }
  .cx-pro-desc-msg {
    position: absolute;
    padding: 34px;
    border: rgba(255, 255, 255, 1) solid 1px;
    background: rgba(64, 214, 255, 0.15);
    z-index: 33;
    top: -270px;
    left: -620px;
  }
  .cx-pro-dmsg {
    width: 230px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
    line-height: 28px;
    color: rgba(255, 255, 255, 1);
  }
  .cx-pro-dbtn {
    width: 126px;
    height: 26px;
    opacity: 1;
    background: rgba(144, 218, 252, 1);
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    margin: 25px auto 0px;
    color:#000;
  }
  .cx-decorator {
    position: absolute;
    width: 9px;
    height: 9px;
    opacity: 1;
    background: rgba(255, 255, 255, 1);
  }
  .cx-decorator-1 {
    top: -5px;
    left: -5px;
    z-index: 11;
  }

  .cx-decorator-2 {
    top: -5px;
    right: -5px;
    z-index: 11;
  }

  .cx-decorator-3 {
    bottom: -5px;
    right: -5px;
    z-index: 11;
  }

  .cx-decorator-4 {
    bottom: -5px;
    left: -5px;
    z-index: 11;
  }

  .cx-pro-ra {
    position: relative;
  }
  .cx-pro-name {
    position: absolute;
    width: 100%;
    top: 159px;
    text-align: center;
    z-index: 33;
    font-size: 44px;
    font-weight: 500;
    letter-spacing: 2px;
    line-height: 0px;
    color: rgba(255, 255, 255, 1);
  }
}
.pc-index {
  .narraw {
    position: absolute;
    top: -190px;
    left: -310px;
    width: 0;
    height: 0;
    z-index: 22;
    border-top: 8px solid transparent;
    border-left: 10px solid #fff;
    border-bottom: 8px solid transparent;
  }

  .narraw-1 {
    top: -190px;
    left: -310px;
  }

  .narraw-2 {
    top: -175px;
    left: -310px;
  }

  .line-a {
    position: absolute;
    width: 50px;
    top: -1px;
    border-top: 1px solid rgba(255, 255, 255, 1);
  }

  .line-b {
    position: absolute;
    width: 300px;
    left: 50px;
    top: -1px;
    border-top: 1px solid rgba(255, 255, 255, 1);
    transform-origin: left top;
    transform: rotateZ(42deg);
  }
  .cx-it-box{
    width: 1600px;
  }
}

.m-index {
  .cx-advantage {
    width: 100%;
  }
  .cx-ad-title {
    width: 100%;
  }
  .swiper-pagination-bullet-active::after {
    width: 32px;
    height: 32px;
    margin-left: -14px;
    margin-top: -14px;
    opacity: 1;
  }
  .cx-ad-txt {
    font-size: 24px;
    white-space: normal;
    width: 90%;
    margin: 0 auto;
  }
  .cx-it-box {
    padding: 20px 0px;
    width: 500px;
  }
  .m-cx-sc-box {
    text-align: center;
  }
  .cx-tech-adv {
    width: 100%;
    height:3100px;
  }
  .cx-pro-bg {
    width: 750px;
    height: 1490px;
    left: -375px;
    top: -745px;
  }
  .cx-pro-box {
    width: 750px;
    height: 1493px;
  }
  .cx-pro-razw {
    top: -130px;
    width: 1000px;
    height: auto;
    top:-20px;
    left:-510px;
  }
  .cx-pro-raz {
    top: 160px;
    width: 400px;
    left:-260px;
    height: auto;
  }

  .narraw {
    position: absolute;
    top: 20px;
    left: -150px;
    width: 0;
    height: 0;
    z-index: 22;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #fff transparent transparent transparent;
  }

  .line-a {
    position: absolute;
    width: 2px;
    height: 120px;
    top: -1px;
    left: 0px;
    background-color: #fff;
  }
  .line-b {
    position: absolute;
    width: 2px;
    height: 140px;
    top: 100%;
    transform-origin: top left;
    background-color: #fff;
    transform: rotate(-43deg);
  }
  .cx-pro-name {
    top: 145px;
  }
  .cx-pro-desc-msg {
    z-index: 33;
    left: -275px;
    top: auto;
    bottom: 0px;
  }
  .cx-pro-ra {
    width: 507px;
  }
  .cx-pro-dbtn {
    width: 174.5px;
    height: 36px;
    font-size: 19px;
    line-height: 36px;
  }
  .cx-pro-dmsg {
    width: 509px;
    font-size: 22px;
    line-height: 39px;
  }
  .cx-join-box {
    width: 750px;
    height: 944px;
  }
  .cx-jo-msg {
    position: relative;
    width: 100%;
    left: 0px;
    top: 89px;
    z-index: 111;
  }
  .cx-jo-vi {
    margin-bottom: 58px;
    margin-top: 49px;
  }
  .cx-jo-btn {
    width: 340px;
    height: 70px;
    font-size: 24px;
    line-height: 70px;
  }
  .cx-jo-img {
    position: absolute;
    width: 750px;
    height: 944px;
    left: 0px;
    top: 0px;
  }
  .cx-ta-desc-box {
    width: 750px;
    float: none;
    margin: 0 auto;
  }
  .cx-td-aimg {
    float: none;
  }
  .cx-td-bimg {
    float: none;
  }
  .cx-ta-item {
    height: auto;
  }
  .cx-ta-tit {
    width: 750px;
    margin-top: 97px;
    overflow: hidden;
  }
  .cx-ta-desc {
    font-size: 24px;
    width: 610px;
    margin: 0 auto 42px;
  }
  .cx-ta-container {
    // height: 2900px;
    height: auto;
  }
}
</style>
