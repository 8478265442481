let is_mobile = false;
let scale = 1;
let fix_height = false;

function setRem() {
  const baseSize = 16;
  const client_width = document.documentElement.clientWidth;
  const client_height = document.documentElement.clientHeight;
  const curr_rate = client_height / client_width;
  if (isMobile()) {
    is_mobile = true;
    scale = client_width / 750;
    fix_height = curr_rate > 1080 / 1920 ? false : true;
  } else {
    is_mobile = false;
    scale = client_width / 1920;
    fix_height = curr_rate > 750 / 1434 ? false : true;
  }
  document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px'
}

function isMobile() {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag;
}

setRem();

window.onresize = function () {
  setRem();
}

function getScrollTop() {
  var scrollPos;
  if (window.pageYOffset) {
    scrollPos = window.pageYOffset;
  } else if (document.compatMode && document.compatMode != "BackCompat") {
    scrollPos = document.documentElement.scrollTop;
  } else if (document.body) {
    scrollPos = document.body.scrollTop;
  }
  return scrollPos;
}


export { scale, is_mobile, fix_height, getScrollTop };