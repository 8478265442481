<template>
  <div
    class="cx-search"
    v-if="show_search"
    :class="is_mobile ? 'm-cx-search' : 'pc-cx-search'"
  >
    <input
      v-model="search_value"
      type="text"
      class="cx-search-input"
      :placeholder="$t('search.input_placehold')"
      @keydown.enter="search"
    />
    <img
      class="cx-search-icon"
      v-if="!is_mobile"
      @click="$emit('toggleSearch')"
      :src="search_close_icon"
    />
    <div class="cx-search-back" @click="$emit('toggleSearch')">
    &lt;
    </div>
  </div>
</template>
<script>
export default {
  name: "Search",
  props: {
    show_search: {
      type: Boolean,
    },
  },
  data() {
    return {
      search_value: "",
      search_close_icon: require("@/assets/search-close.png"),
    };
  },
  methods: {
    search() {
      if (this.search_value) {
        this.$router.push("/search/" + this.search_value);
        this.$emit("toggleSearch");
      }
    },
  },
  components: {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.cx-search {
  width: 845px;
  height: 360px;
  position: absolute;
  left: 50%;
  top: 76px;
  margin-left: -422.5px;
  background-color: #535455;
}
.cx-search-input {
  width: 660px;
  height: 60px;
  color: #fff;
  border-bottom: #9a9a9a solid 1px;
  margin-top: 120px;
  color:#fff;
  font-size: 33px;
}
.cx-search-input::placeholder{
  color:#999;
}
.cx-search-icon {
  width: 28px;
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}

.m-cx-search {
  position: fixed;
  width: 100%;
  z-index: 99999;
  height: 100%;
  left: 0px;
  top: 0px;
  margin-left: 0px;
  margin-top: 0px;
  .cx-search-input {
    position: absolute;
    margin-top: -100px;
    top: 50%;
    left: 45px;
  }
  .cx-search-back {
    position: absolute;
    width: 90px;
    height: 90px;
    color: #fff;
    font-size: 40px;
    top: 120px;
    text-align: center;
    line-height: 90px;
  }
}
</style>
