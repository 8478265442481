import http from "./http";

export default {
  banner(){
    return http.get("/api/banner", {});    
  },

  getNews(params){
    return http.get("/api/article", params);
  },

  getNewInfo(params){
    return http.get("/api/articleDetail", params);
  },

  districtList(params){
    return http.get("/api/districtList", params);
  },

  districtLevelList(params){
    return http.get("/api/districtLevelList", params);
  },

  recruitList(params){
    return http.get("/api/recruitList", params);
  },

  recruitDetail(params){
    return http.get("/api/recruitDetail", params);
  },

  sequenceList(params){
    return http.get("/api/sequenceList", params);
  },

  positionType(params){
    return http.get("/api/positionType", params);
  },
  
  categoryList(params){
    return http.get("/api/categoryList", params);
  },
  
  resumeAdd(params){
    return http.post("/api/resumeAdd", params);
  },

  consultLogAdd(params){
    return http.post("/api/consultLogAdd", params);
  },

  courseList(params){
    return http.get("/api/historyList", params);
  },

  certificationList(params){
    return http.get("/api/certificationList",params);
  },

  upload(params){
    return http.post("/api/upload",params);
  },

  searchRecruit(params){
    return http.post("/api/searchRecruit",params);
  },
  
  getWorkPlace(params){
    return http.get("/api/getWorkPlace",params);
  },

  search(params){
    return http.get("/api/search",params);
  }
}