<template>
  <div class="cx-footer" :class="is_mobile ? 'm-cx-footer' : 'pc-cx-footer'">
    <div class="cx-f-box">
      <div class="cx-fb" v-if="!is_mobile">
        <img class="cx-footer-logo" :src="logo[locale]" />
        <div class="cx-fl-lab">{{ $t("footer.logo_labe") }}</div>
      </div>
      <div class="cx-f-navs">
        <ul :class="spread_proction?'act':''">
          <li @click="toggleSpread('spread_proction')">
            {{ $t("footer.company_production") }}
            <img v-if="is_mobile" class="m-nav-narrow" :src="nav_up_icon" />
          </li>
          <li><router-link to="/pro/razorDCX">{{ $t("footer.razor_dcx") }}</router-link></li>
          <li><router-link to="/pro/razorWareX">{{ $t("footer.razor_ware_x") }}</router-link></li>
        </ul>
        <ul :class="spread_about?'act':''">
          <li @click="toggleSpread('spread_about')">{{ $t("footer.about_us") }}
            <img v-if="is_mobile" class="m-nav-narrow" :src="nav_up_icon" />
          </li>
          <li><router-link to="/about#profile">{{ $t("footer.company_introduction") }}</router-link></li>
          <li><router-link to="/about#mission">{{ $t("footer.company_vision") }}</router-link></li>
          <li><router-link to="/about#course">{{ $t("footer.company_process") }}</router-link></li>
          <li><router-link to="/about#contact">{{ $t("footer.contact_us") }}</router-link></li>
        </ul>
        <ul :class="spread_join?'act':''">
          <li @click="toggleSpread('spread_join')">{{ $t("footer.join_us") }}
            <img v-if="is_mobile" class="m-nav-narrow" :src="nav_up_icon" />
          </li>
          <li><router-link to="/join/recruitment/social">{{ $t("footer.social_recruitment") }}</router-link></li>
          <li><router-link to="/join/recruitment/campus">{{ $t("footer.campus_recruitement") }}</router-link></li>
          <li><router-link to="/join/recruitment/internal">{{ $t("footer.internal_recruitement") }}</router-link></li>
        </ul>
        <ul class="m-mi-ul">
          <li>{{ $t("footer.social_media") }}</li>
          <li class="m-cx-mid">
            <img class="cx-c-med" @click="showWeixinOffice" :src="i_wx_icon" />
            <!-- <img class="cx-c-med" :src="i_webo" /> -->
          </li>
        </ul>
      </div>
    </div>
    <div class="cx-f-copyright">
      <a href="http://beian.miit.gov.cn" target="_blank">{{ $t("footer.icp_number") }}
        <!-- <img class="cx-police" :src="police_icon" />
        {{ $t("footer.net_icp_number") }} -->
      </a>
    </div>
    <cx-cover-layout v-if="show_wx_office">
        <img class="office-acount" :src="wx_office_account" />
        <img @click="hideOffice" class="wx-office-close" :src="wx_office_close" />
    </cx-cover-layout>
  </div>
</template>

<script>
import CoverLayout from "../components/CoverLayout.vue"
export default {
  name: "Header",
  props: {
    locale: {
      type: String,
    },
  },
  data() {
    return {
      logo: {
        zh: require("@/assets/logo-zh.png"),
        en: require("@/assets/logo-en.png"),
      },
      nav_up_icon: require("@/assets/nav-up-icon.png"),
      i_wx_icon: require("@/assets/i-wx-icon.png"),
      i_webo: require("@/assets/i-webo.png"),
      police_icon: require("@/assets/police-icon.png"),
      wx_office_account: require("@/assets/wx_office_account.jpeg"),
      wx_office_close: require("@/assets/close-coop.png"),
      
      spread_proction:false,
      spread_about:false,
      spread_join:false,
      show_wx_office:false
    };
  },
  methods:{
    hideOffice(){
      this.show_wx_office=false;
    },
    showWeixinOffice(){
      this.show_wx_office=!this.show_wx_office;
    },
    toggleSpread(name){
      if(this.is_mobile){
        if(name=='spread_proction'){
          this.spread_proction=!this.spread_proction
        }else if(name=='spread_about'){
          this.spread_about=!this.spread_about
        }if(name=='spread_join'){
          this.spread_join=!this.spread_join
        }
      }
    }
    
  },
  components: {
  'cx-cover-layout':CoverLayout
  },
  mounted() {
    
  },
};
</script>

<style lang="less" scoped>
.cx-footer {
  position: relative;
  .cx-f-box {
    border-bottom: rgba(166, 166, 166, 1) solid 1px;
    .cx-f-navs {
      overflow: hidden;
    }
  }
  .cx-fb {
    .cx-footer-logo {
      width: 251px;
    }
    .cx-fl-lab {
      font-size: 14px;
      color: #fff;
      margin-top: 15px;
      margin-left: 12px;
      text-align: left;
    }
  }
  .office-acount{
    width: 400px;
    margin-left:-200px;
    margin-top:-200px;
  }
  .wx-office-close{
    position: absolute;
    width: 40px;
    margin-left:0px;
    margin-top:-250px;
  }
}

.pc-cx-footer {
  height: 360px;
  .cx-f-box {
    height: 284px;
    .cx-fb {
      position: absolute;
      width: 251px;
      top: 61px;
      left: 220.5px;
    }
    .cx-f-navs {
      position: relative;
      margin-top: 69px;
      float: right;
      ul {
        float: left;
        height: 170px;
        width: 250px;
        color: #fff;
        li {
          font-size: 14px;
          color: #fff;
          margin-bottom: 15px;
          text-align: left;
          .cx-c-med {
            width: 47px;
            margin-right: 10px;
          }

          a{
            color: #fff;
          }
        }
        li:first-child {
          font-size: 18px;
          margin-bottom: 20px;
        }
      }
    }
  }
  .cx-f-copyright {
    line-height: 89px;
    line-height: 89px;
    color: #fff;
    font-size: 12px;
    a{
      color: #fff;
    }
    .cx-police {
      width: 18px;
      margin-left: 100px;
      margin-right: 10px;
    }
  }
  .cx-fl-lab {
    width: 350px;
  }
}
.m-cx-footer {
  overflow: hidden;
  .cx-f-box {
    padding: 91px 0px 40px;
    .cx-f-navs {
      ul {
        display: block;
        color: #fff;
        text-align: left;
        border-bottom: rgba(61, 61, 61, 1) solid 1px;
        height: 65px;
        overflow: hidden;
        transition: all 300ms linear;
        li {
          position: relative;
          font-size: 24px;
          color: #fff;
          margin-left:84px;
          line-height: 64px;
          a{
            color:#fff;
          }
          .cx-c-med {
            width: 79px;
            margin-left: 20px;
          }
          .m-nav-narrow{
            position: absolute;
            right:61px;
            top:20px;
            width: 27.5px;
            transform: rotate(0deg);
            transition: all 300ms linear;
          }
        }
        li.m-cx-mid{
          margin-left:64px;
        }
        li:first-child {
          font-size: 26px;
          margin-left:54px;
          margin-bottom:20px;
        }
      }
      ul.act{
        height: auto;
        li{
          .m-nav-narrow{
            top:10px;
            transform: rotate(180deg);
          }
        }
      }
      ul.m-mi-ul{
        height: auto;
      }
    }
  }
  .cx-f-copyright {
    line-height: 75px;
    line-height: 75px;
    color: #fff;
    font-size: 18px;
    a{
      color: #fff;
    }
    .cx-police {
      width: 27px;
      margin-left: 100px;
      margin-right: 10px;
    }
  }
}
</style>
